import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import getters from "./getters";
import common from "./modules/common";
import loading from "./modules/loading";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    common,
    loading,
  },
  state: {
    globalInfo: {},
    authorization: "",
  },
  mutations: {
    setGlobalInfo(state, obj) {
      state.globalInfo = obj;
      // console.log("执行了vuex mutation方法", obj);
    },
    SET_AUTHORIZATION(state, value) {
      state.authorization = value;
    },
  },
  actions: {
    commitSetGlobalInfo({ commit }, data) {
      commit("setGlobalInfo", data);
      // console.log("执行了vuex actions方法", data);
    },
  },
  getters,
  // 这里引入vuex-persistedstate插件,实现数据持久化
  plugins: [createPersistedState()],
});

export default store;
