/*
 * @Author: yanzq
 * @Date: 2021-12-17 21:27:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-07 13:51:26
 * @Description: 请填写简介
 */
import request from "../utils/request.js";
// 认领企业信息列表接口
export function getList(data) {
  return request({
    method: "post",
    url: "/public/app/claim/list",
    params: data,
  });
}

// 认领企业信息列表退回接口
export function editBack(data) {
  return request({
    method: "post",
    url: "/public/app/claim/back",
    params: data,
  });
}

// 获取街道组织机构信息接口
export function getStreetOrgInfo() {
  return request({
    method: "get",
    url: "/public/app/claim/getStreetOrgInfo",
  });
}

// 获取数量
export function getcount() {
  return request({
    method: "get",
    url: "/public/app/claim/count",
  });
}

export function getToken(data) {
  return request({
    method: "get",
    url: "/public/app/claim/getToken",
    params: data,
  });
}

//获取用户基本信息UnionID机制
export function geOrtUser(data) {
  return request({
    method: "get",
    url: "/public/message/geOrtUser",
    params: data,
  });
}

//获取用户基本信息UnionID机制(获取access_token)
export function getTokenUnionID(data) {
  return request({
    method: "post",
    url: "/public/message/getToken",
    params: data,
  });
}

//获取用户信息（通过网页授权）
export function getUser(data) {
  return request({
    method: "get",
    url: "/public/message/getUser",
    params: data,
  });
}

//上传头像
export function upload(data) {
  return request({
    method: "post",
    url: "/public/app/claim/upload",
    data,
  });
}

//获取用户信息
export function getUserInfo(data) {
  return request({
    method: "get",
    url: "/public/app/claim/getAccountInfo",
    params: data,
  });
}

// 获取企业信息   /public/app/claim/details
export function getDetail(data) {
  return request({
    method: "get",
    url: `/public/app/claim/details?uuid=${data.uuid}`,
  });
}

//认领 /public/app/claim/toClaim
export function toClaim(data) {
  return request({
    method: "post",
    url: `/public/app/claim/toClaim`,
    params: data,
  });
}

//我的消息模块-消息列表
export function getMessageListToWeChat(data) {
  return request({
    method: "get",
    url: `/public/message/getMessageListToWeChat`,
    params: data,
  });
}

//我的消息模块-消息详情
export function getMessageDetail(data) {
  return request({
    method: "post",
    url: `/public/message/getMessageDetail`,
    params: data,
  });
}

//我的消息模块-反馈
export function backMessage(data) {
  return request({
    method: "post",
    url: `/public/message/backMessage`,
    params: data,
  });
}

//个人中心模块-保存法人
export function saveAccountAndCompany(data) {
  return request({
    method: "post",
    url: `/public/message/saveAccountAndCompany`,
    data: data,
  });
}

//个人中心模块-返回群组列表
export function getGroup(data) {
  return request({
    method: "post",
    url: `/public/message/getGroup`,
    params: data,
  });
}

//个人中心模块-保存群组用户
export function saveAccountByGroup(data) {
  return request({
    method: "post",
    url: `/public/message/saveAccountByGroup`,
    data: data,
  });
}

//个人中心模块-获取法人企业列表
export function getEnterpriseSupervisionList(data) {
  return request({
    method: "post",
    url: `/public/message/getEnterpriseSupervisionList`,
    params: data,
  });
}

//个人中心模块-获取法人企业列表
export function getWechatEnterpriseSupervisionCount(data) {
  return request({
    method: "post",
    url: `/public/message/getWechatEnterpriseSupervisionCount`,
    params: data,
  });
}

//获取秘钥与时间戳
export function checkSign(data) {
  return request({
    method: "post",
    url: `/public/message/checkSign`,
    params: data,
  });
}
export function getOpenId(data) {
  return request({
    method: "get",
    url: `/public/message/getOpenId`,
    params: data,
  });
}

export function checkUserIn(data) {
  return request({
    method: "post",
    url: `/public/message/checkUserIn`,
    params: data,
  });
}

//根据统一信用代码查询对应的数据
export function selectOneEnterpriseSupervisionByShxydm(data) {
  return request({
    method: "post",
    url: `/public/message/selectOneEnterpriseSupervisionByShxydm`,
    params: data,
  });
}

//完善信息
export function perfectWechatAccount(data) {
  return request({
    method: "post",
    url: `/public/message/perfectWechatAccount`,
    params: data,
  });
}

//
export function getCompanyTypeTree(data) {
  return request({
    method: "post",
    url: `/public/pushMessage/getCompanyTypeTree`,
    data,
  });
}
//
export function getHyTree(data) {
  return request({
    method: "post",
    url: `/public/pushMessage/getHyTree`,
    data,
  });
}

///
export function updateOneEnterpriseSupervision(data) {
  return request({
    method: "post",
    url: `public/message/updateOneEnterpriseSupervision`,
    params: data,
  });
}
export function unboundAccountAndCompany(data) {
  return request({
    method: "post",
    url: `/public/message/unboundAccountAndCompany`,
    params: data,
  });
}
