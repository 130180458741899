/*
 * @Author: 陈乾
 * @Date: 2021-12-20 22:54:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-24 15:47:02
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/utils/wx.js
 */
import wx from "weixin-js-sdk";
import { checkSign } from "../api/home";

//初始化微信sdk
const wxShowMenu = () => {
  let url = location.href;
  checkSign({ waGroup: url }).then((res) => {
    console.log("微信配置----------", res);
    let data = res.data;
    wx.config({
      debug: false, //生产环境需要关闭debug模式  测试环境下可以设置为true 可以在开发者工具中查看问题
      appId: "wx47b46760287ffd31", //appId通过微信服务号后台查看
      timestamp: data.timestamp, //生成签名的时间戳
      nonceStr: data.nonceStr, //生成签名的随机字符串
      signature: data.signature, //签名
      jsApiList: [
        //需要调用的JS接口列表
        "updateAppMessageShareData", //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0） 新接口
        "updateTimelineShareData", //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0） 老接口
        "onMenuShareTimeline", //分享到朋友圈 老接口
        "onMenuShareAppMessage", //分享给盆友 老接口
        "closeWindow",
      ],
    });
    wx.error(function (res) {
      //alert(JSON.stringify(res))
      console.log("调用失败，wx.err,", res);
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    });
    wx.ready(function () {
      // 分享给朋友，新接口
      wx.updateAppMessageShareData({
        title: "测试分享标题", // 分享标题
        desc: "测试分享描述", // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: require("../assets/images/x.png"), // 分享图标
        success: function () {
          // 设置成功
          console.log("updateAppMessageShareData分享给朋友，新接口", res);
        },
      });

      // 分享到朋友圈，新接口
      wx.updateTimelineShareData({
        title: "测试分享标题",
        link: window.location.href,
        imgUrl: require("../assets/images/x.png"),
        desc: "测试分享描述",
        success: function (res) {
          console.log("updateTimelineShareData分享到朋友圈，新接口", res);
        },
      });
      // // 分享给朋友，老接口
      wx.onMenuShareAppMessage({
        title: "测试分享标题",
        link: window.location.href,
        imgUrl: require("../assets/images/x.png"),
        desc: "测试分享描述",
        success: function (res) {
          console.log("onMenuShareAppMessage，分享给朋友，老接口", res);
        },
      });
      // // 分享到朋友圈，老接口
      wx.onMenuShareTimeline({
        title: "测试分享标题",
        link: window.location.href,
        imgUrl: require("../assets/images/x.png"),
        desc: "测试分享描述",
        success: function (res) {
          console.log("onMenuShareTimeline, 分享到朋友圈，老接口", res);
        },
      });

      wx.hideMenuItems({
        menuList: ["menuItem:copyUrl"], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
      });
    });
  });
};
export { wxShowMenu };
