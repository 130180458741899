<!--
 * @Author: 陈乾
 * @Date: 2021-12-20 02:47:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-08 18:50:21
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/App.vue
-->
<template>
  <div id="app">
    <!-- <Header /> -->
    <router-view />
    <!-- <van-dialog v-model="show" title="绑定" @confirm="confirmBd">
      <van-field type="digit" v-model="phone" label="手机号" placeholder="请输入手机号" required />
    </van-dialog> -->
    <loading />
  </div>
</template>

<script>
// import Header from '@/components/header/header'
import loading from "@/components/loading/loading";

import {
  getOpenId,
  checkUserIn,
  getTokenUnionID,
  getWechatEnterpriseSupervisionCount,
  getUser,
  geOrtUser,
} from "./api/home";
import { wxShowMenu } from "./utils/wx";
import { mapMutations, mapActions } from "vuex";
// import axios from "axios";
export default {
  data() {
    return {
      loading,
      phone: "",
      show: false,
      isFa: false,
      active: "",
      list: [
        {
          url: "sy1",
          content:
            "朱党其在开展联系基层代表和人民群众活动时强调密切联系群众反映群…",
        },
        {
          url: "sy2",
          content: "前新社区联合街道应急办开展高层小区地下车库防汛演练",
        },
        {
          url: "sy3",
          content: "近日，在亚运会轮滑馆外，随着2022年第19届亚运会健身神曲",
        },
        {
          url: "sy4",
          content:
            "近日，我区正式进入“双抢”时间，据了解，今年我区早稻种植面积...",
        },
        {
          url: "sy5",
          content: "近日，在亚运会轮滑馆外，随着2022年第19届亚运会健身神曲",
        },
      ],
    };
  },
  components: {
    loading,
  },
  created() {
    this.getCode1();
  },
  mounted() {
    // console.log('type', this.$route.query.type);
    // this.getCode2();
    // this.request();
    // this.aaaa();
    window.$vm = this;
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(["setGlobalInfo"]),
    ...mapActions(["commitSetGlobalInfo"]),
    confirmBd() {
      const openid = localStorage.getItem("openid");
      const waShxydm = this.GetQueryString("waShxydm");
      const waGroup = this.GetQueryString("waGroup");
      if (!openid) {
        this.$toast("请稍候...");
        return false;
      }
      if (!this.phone) {
        this.$dialog.alert({
          title: "提示",
          message: "请填写手机号",
        });
        return false;
      }
      checkUserIn({
        waOpenId: openid,
        waAccountPhone: this.phone,
        waShxydm,
        waGroup,
      })
        .then((res) => {
          if (res.success) {
            this.show = false;
            this.$toast.success("绑定成功");
          } else {
            this.$toast.fail("绑定失败");
          }
        })
        .catch(() => {
          this.$toast.fail("绑定失败");
        });
    },
    aaaa() {
      wxShowMenu();
    },
    getCode2() {
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=https://qtqyzx.qiantang.gov.cn?isShare=true&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
    },
    getCode1() {
      let user_appid = "wx47b46760287ffd31"; //个人开发者appid
      let type = this.$route.query.type;
      let local = window.location.href;
      let wx_code = this.GetQueryString("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
      //判断有没有code
      if (wx_code == null || wx_code === "") {
        // console.log("没有code跳转");
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${user_appid}&redirect_uri=${encodeURIComponent(
          local
        )}&response_type=code&scope=snsapi_userinfo&state=${type}#wechat_redirect`;
        //获取code的地址。获取成功重定向后地址栏中将会带有code
        window.location.href = url;
      } else {
        // console.log("有code开始调用接口:", wx_code);
        let type = this.GetQueryString("state");
        //网页授权
        getOpenId({
          code: wx_code,
        }).then((res) => {
          if (res.openid) {
            localStorage.setItem("openid", res.openid);
            getUser({
              openid: res.openid,
              lang: "zh_CN",
              access_token: res.access_token,
            }).then((res2) => {
              getWechatEnterpriseSupervisionCount({
                waOpenId: res.openid,
                waBind: "1",
              }).then((res) => {
                this.count = res.data.length || 0;
                if (res.data && res.data.length) {
                  this.isFa = true;
                  this.commitSetGlobalInfo(res2);
                  this.commitSetGlobalInfo(
                    Object.assign(this.$store.state.globalInfo, {
                      nickname: res.data[0].waAccountName,
                    })
                  );
                  // sessionStorage.setItem("username", res.data[0].waAccountName);
                } else {
                  this.commitSetGlobalInfo(res2);
                  // this.username = sessionStorage.getItem("username");
                }
              });

              // console.log(this.$store.state.globalInfo, "globalInfo");
              // localStorage.setItem("userImg", res2.headimgurl);
              // sessionStorage.setItem("username", res.nickname);
              // console.log(sessionStorage.getItem("username"), "21221");
              localStorage.setItem("userInfo", JSON.stringify(res2));
              this.$router.push("/" + type);
            });
          }
        });

        //   //网页授权
        //   getOpenId({
        //     code: wx_code,
        //   }).then((res) => {
        //     console.log(res, "11111wx_code");
        //     if (res.openid) {
        //       // localStorage.setItem("openid", res.openid);
        //       getUser({
        //         openid: res.openid,
        //         lang: "zh_CN",
        //         access_token: res.access_token,
        //       }).then((res) => {
        //         console.log(res, "linkRes222222");
        //         // this.setGlobalInfo(res);
        //         this.waHeadImg = res.headimgurl;
        //       });
        //     }
        //   });
      }
    },
    // getIndent(openid) {
    //   getWechatEnterpriseSupervisionCount({
    //     waOpenId: openid,
    //   }).then(res => {
    //     if (res.data && res.data.length) {
    //       this.isFa = true;
    //     }
    //     if (!this.isFa) {
    //       this.show = true;
    //     }
    //   });
    // },
    request() {
      // let url = location.href.split("#")[0];
      // checkSign({ waGroup: url })
      // .then((res) => {
      // wx.config({
      //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //   appId: "wx47b46760287ffd31", // 必填，公众号的唯一标识
      //   timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      //   nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      //   signature: res.data.signature, // 必填，签名
      //   jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      // });
      // wx.ready(function () {
      //   wx.updateAppMessageShareData({
      //     title: "", // 分享标题
      //     desc: "", // 分享描述
      //     link: "https://qtqyzx.qiantang.gov.cn/#/myMessage", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      //     imgUrl: "", // 分享图标
      //     success: function () {
      //       // 设置成功
      //       console.log("成功");
      //     },
      //   });
      // });
      // })
      // .catch((e) => {
      //   console.log("接口失败");
      // });
      let code = this.GetQueryString("code");
      getOpenId({
        code: "081teXll2nd1l841Tfll2yUb2a2teXl3",
      }).then((res) => {
        if (
          !localStorage.getItem("openid") ||
          localStorage.getItem("openid") == "undefined"
        ) {
          localStorage.setItem("openid", res.openid);
        }
      });
    },
    GetQueryString(name) {
      let search = "";
      search =
        window.location.search.substr(1) || window.location.hash.split("?")[1];
      if (!search) return null;
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = search.match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // qe() {
    //   this.$router.push({ path: "/enterprise-claim" });
    // },
    mine() {
      this.$router.push({ path: "/" });
    },
    myMessage() {
      this.$router.push({ path: "/myMessage" });
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: rem(24);
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
