//函数节流
const throttle = (fn, timer) => {
  if (timer == null || timer == undefined) {
    timer = 1000;
  }
  let lastTime = null;
  // 返回新的函数
  return function () {
    let nowTime = +new Date();
    if (nowTime - lastTime > timer || !lastTime) {
      fn.apply(this, arguments); //将this和参数传给原函数
      lastTime = nowTime;
    }
  };
};

/**
 * 防抖函数
 * @param {function} func 方法
 * @param {Number} wait 该时长内仅触发1次
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */
const debounce = (func, wait, immediate) => {
  var timeout;
  var asyncHandler;
  return async function () {
    var context = this;
    var args = arguments;
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    asyncHandler = new Promise(resolve => {
      timeout = setTimeout(() => {
        timeout = null;
        if (!immediate) {
          resolve(func.apply(context, args));
        }
      }, wait);
      return callNow ? Promise.resolve(func.apply(context, args)) : asyncHandler;
    });
  };
};
function TMap(key) {
  return new Promise(function (resolve, reject) {
    window.init = function () {
      resolve(qq); //注意
    };
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'http://map.qq.com/api/js?v=2.exp&callback=init&key=' + key;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

// 判断是否是iphoneX
const isIphonex = () => {
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  }
  return false;
};

const isMobile = mobile => {
  const mobileRegExp = /^1[3456789]\d{9}$/;
  return mobileRegExp.test(mobile);
};

//字符串转换为时间戳
const getDateTimeStamp = dateStr => {
  return Date.parse(dateStr.replace(/-/gi, '/'));
};

// 时间转换为x天前 x周前等等
const getDateDiff = dateTimeStamp => {
  dateTimeStamp = getDateTimeStamp(dateTimeStamp);
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  // var halfamonth = day * 15
  var month = day * 30;
  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    return;
  }
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  let result;
  if (monthC >= 1) {
    result = '' + parseInt(monthC) + '月前';
  } else if (weekC >= 1) {
    result = '' + parseInt(weekC) + '周前';
  } else if (dayC >= 1) {
    result = '' + parseInt(dayC) + '天前';
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC) + '小时前';
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前';
  } else result = '刚刚';
  return result;
};

// 判断是否是数组
function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

// 深拷贝
const deepClone = obj => {
  let newObj;
  // 判断复制的目标是不是数组
  if (isArray(obj)) {
    newObj = [];
  } else if (typeof obj === 'object') {
    newObj = {};
  } else {
    newObj = obj;
  }
  if (typeof obj === 'object') {
    for (let item in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, item)) {
        if (obj[item] && typeof obj[item] === 'object') {
          newObj[item] = deepClone(obj[item]);
        } else {
          newObj[item] = obj[item];
        }
      }
    }
  }
  return newObj;
};

// 后端返回的相对路径导致本地无法显示图片，故判断本地开发环境，加上所在域名进行图片url处理
const addDomain = url => {
  return process.env.NODE_ENV === 'development' ? `https://wh.risenoa.com:23015${url}` : url;
};

export default {
  debounce,
  isIphonex,
  isMobile,
  isArray,
  getDateDiff,
  deepClone,
  addDomain,
  TMap,
};
