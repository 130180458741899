const state = {
  // 登录信息
  loginInfo: null,
  token: null,
  userInfo: null,
  // globalInfo: {},
};

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
  getToken: (state) => {
    let token = state.token ? state.token : "";

    return token;
  },
  setUser: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  // setGlobalInfo(state, obj) {
  //   state.globalInfo = obj;
  //   console.log("执行了vuex mutation方法", obj);
  // },
};

const actions = {
  /* demo
  commitMiniCartShow: ({ commit }, data) => {
    commit('miniCartShow', data)
  } */
  // commitSetGlobalInfo({ commit }, data) {
  //   commit("setGlobalInfo", data);
  //   console.log("执行了vuex actions方法", data);
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
