/*
 * @Author: yanzq
 * @Date: 2021-12-17 21:27:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-07 17:16:57
 * @Description: 请填写简介
 */
// import { resolve } from "core-js/fn/promise";
import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: (resolve) => require(["@/views/home"], resolve),
  //   meta: {
  //     title: "首页",
  //   },
  // },
  {
    path: "/map",
    name: "map",
    component: (resolve) => require(["@/views/map"], resolve),
    meta: {
      title: "地图",
    },
  },
  {
    path: "/",
    redirect: "/userCenter",
  },
  {
    path: "/userCenter",
    name: "userCenter",
    component: (resolve) => require(["@/views/userCenter/index.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },

  {
    path: "/bindList",
    name: "bindList",
    component: (resolve) =>
      require(["@/views/userCenter/bindList.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/bindCorporation",
    name: "bindCorporation",
    component: (resolve) =>
      require(["@/views/userCenter/bindCorporation.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/authorizationManagement",
    name: "authorizationManagement",
    component: (resolve) =>
      require(["@/views/userCenter/authorizationManagement.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/authorizationList",
    name: "authorizationList",
    component: (resolve) =>
      require(["@/views/userCenter/authorizationList.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/infoComplete",
    name: "infoComplete",
    component: (resolve) =>
      require(["@/views/userCenter/infoComplete.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/infoList",
    name: "infoList",
    component: (resolve) =>
      require(["@/views/userCenter/infoList.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/phone-verification",
    name: "phone-verification",
    component: (resolve) => require(["@/views/phone-verification"], resolve),
    meta: {
      title: "手机号码验证",
    },
  },
  {
    path: "/enterprise-claim",
    name: "enterprise-claim",
    component: (resolve) => require(["@/views/enterprise-claim"], resolve),
    meta: {
      title: "企业认领平台",
    },
  },
  {
    path: "/detail",
    name: "detail",
    component: (resolve) => require(["@/views/detail"], resolve),
    meta: {
      title: "认领详情修改",
    },
  },
  {
    path: "/back",
    name: "back",
    component: (resolve) => require(["@/views/back"], resolve),
    meta: {
      title: "认领退回",
    },
  },
  {
    path: "/myMessage",
    name: "myMessage",
    component: (resolve) => require(["@/views/myMessage"], resolve),
    meta: {
      title: "我的消息",
    },
  },
  {
    path: "/mine",
    name: "mine",
    component: (resolve) => require(["@/views/mine"], resolve),
    meta: {
      title: "我的消息",
    },
  },
  {
    path: "/myMessage-view",
    name: "myMessage-view",
    component: (resolve) => require(["@/views/myMessage-view"], resolve),
    meta: {
      title: "我的消息详情",
    },
  },
  {
    path: "/improve-information",
    name: "improve-information",
    component: (resolve) =>
      require(["@/views/userCenter/improve-information.vue"], resolve),
    meta: {
      title: "登记信息复核",
    },
  },
  {
    path: "/loan",
    name: "loan",
    component: (resolve) => require(["@/views/Loan"], resolve),
    meta: {
      title: "贷款产品展示",
    },
  },
  {
    path: "/loan2",
    name: "loan2",
    component: (resolve) => require(["@/views/Loan2"], resolve),
    meta: {
      title: "适配产品",
    },
  },
  {
    path: "/loanDetail",
    name: "loanDetail",
    component: (resolve) => require(["@/views/LoanDetail"], resolve),
    meta: {
      title: "贷款产品详情",
    },
  },
  {
    path: "/evaluation",
    name: "evaluation",
    component: (resolve) => require(["@/views/evaluation"], resolve),
    meta: {
      title: "金融产品评价-",
    },
  },
  {
    path: "/evaluationDetail",
    name: "evaluationDetail",
    component: (resolve) => require(["@/views/evaluationDetail"], resolve),
    meta: {
      title: "金融产品评价详情",
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "hash",
});

router.beforeEach((to, form, next) => {
  // console.log(to, form);
  let title = to.meta.title;
  if (title) {
    document.title = title;
    next();
  }
});

export default router;
