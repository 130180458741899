/*
 * @Author: 陈乾
 * @Date: 2021-12-20 02:47:18
 * @LastEditors: 陈乾
 * @LastEditTime: 2021-12-20 23:51:41
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/config/env.production.js
 */

// 正式
module.exports = {
  title: "钱塘区市场主体",
  // casPath: '', //
  // baseApi: '/prod-api' // 测试OA地址(政务钉钉) api 请求地址
  baseApi: "/api", // 正式环境OA地址（浙政钉） api 请求地址
  baseApi2: "/prod-api",
};
