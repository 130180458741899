/*
 * @Author: 陈乾
 * @Date: 2021-12-20 02:47:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-08 18:06:03
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/main.js
 */
import "@/common/style/form.scss";
import Vant from "vant";
//复制文字插件
import VueClipboard from "vue-clipboard2";
import "vant/lib/index.css";
import "vant/lib/icon/local.css";

// import "@/styles/src/index.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./utils/index";
import "./utils/size";

import VConsole from "vconsole";
// if (process.env.NODE_ENV == "development") {
//   const vConsole = new VConsole();
//   Vue.use(vConsole);
// }
// const vConsole = new VConsole();
// Vue.use(vConsole);

Vue.use(Vant);
Vue.use(VueClipboard);

// ztree
import $ from "jquery";
import "@ztree/ztree_v3/js/jquery.ztree.core.js";
import "@ztree/ztree_v3/css/zTreeStyle/zTreeStyle.css";

Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
